import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectFilteredData } from '../storage/store';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import player1 from '../assets/images/banner-slide-1.png';
import player2 from '../assets/images/banner-slide-2.png';
import player3 from '../assets/images/banner-slide-3.png';
import player4 from '../assets/images/banner-slide-4.png';
import { Icon } from "@mui/material";

const bannerImageRandom = [player1, player2, player3, player4];

const Banner = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const currentItems = useSelector(selectFilteredData).slice(0, 4);
    //const { loading, error } = useSelector(state => state.data);
    const sliderRef = useRef(null);
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        // autoplay: true,
        // autoplaySpeed: 5000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: () => handleAfterChange(),
    };

    const handleAfterChange = () => {
        if (sliderRef.current) {
            const slickPrev = sliderRef.current.innerSlider?.list?.querySelector('.slick-prev');
            const slickNext = sliderRef.current.innerSlider?.list?.querySelector('.slick-next');
            
            if (prevButtonRef.current && slickPrev) {
                if (slickPrev.classList.contains('slick-disabled')) {
                    prevButtonRef.current.classList.add('slick-disabled');
                } else {
                    prevButtonRef.current.classList.remove('slick-disabled');
                }
            }
            
            if (nextButtonRef.current && slickNext) {
                if (slickNext.classList.contains('slick-disabled')) {
                    nextButtonRef.current.classList.add('slick-disabled');
                } else {
                    nextButtonRef.current.classList.remove('slick-disabled');
                }
            }
        }
    };

    useEffect(() => {
        if (sliderRef.current) {
            handleAfterChange();
        }
    }, [currentItems]);



    
    return (
        <div className="slick-container">
            <Slider ref={sliderRef} {...settings}>
                {currentItems ? (
                            currentItems.map(item => {
                                const isBroadcast = item.IsLive === item.NowPlaying.toString();
                                const sched = new Date(item.TimeStart);
                                const month = sched.getMonth();
                                // Generate a random image for each slide
                                const randomImage = bannerImageRandom[Math.floor(Math.random() * bannerImageRandom.length)];
                                
                                return (
                                    <div key={item.MatchID} className={`banner-slide img-${item.Type}`} data-matchid={item.MatchID}>
                                        <div className="matchinner">
                                            <div className="liveNow"><span className={`isLive ${isBroadcast ? 'show' : 'hide'}`}>Live</span></div>
                                            <div className="schedule">
                                                <p><img src="/img/date_icon.svg" alt="date icon" /> {months[month] + " " + sched.getDate()}</p>
                                                <p><img src="/img/time_icon.svg" alt="time icon" /> {sched.toLocaleTimeString([], { timeStyle: 'short', timeZone: 'Asia/Dhaka' })}</p>
                                            </div>
                                            <div className="League">{item.League}</div>
                                            <div className="matchwrapper">
                                                <p className="teams">{item.Home}</p>
                                                <p className="versus">VS</p>
                                                <p className="teams">{item.Away}</p>
                                            </div>
                                            <div className="watch-link" data-matchid={item.MatchID}>
                                                <a href={`${process.env.REACT_APP_LIVE_ENDPOINT}/${item.MatchID}`}>
                                                    Watch Live
                                                </a>
                                            </div>
                                        </div>
                                        <div className="matchImage">
                                            <img src={randomImage} alt={`football ${Math.floor(Math.random() * bannerImageRandom.length)}`} />
                                        </div>
                                    </div>
                                );
                            })
                ) : (

                    
                        <div className={`banner-slide imgFOOTBALL`} data-matchid="12312">
                            <div className="matchinner">
                                <div className="liveNow"><span className={`isLive ${false ? 'show' : 'hide'}`}>Live</span></div>
                                <div className="schedule">
                                    <p><img src="/img/date_icon.svg" alt="date icon" /> SEPT 10 </p>
                                    <p><img src="/img/time_icon.svg" alt="time icon" /> 12:00 PM</p>
                                </div>
                                <div className="League">Premier Leage</div>
                                <div className="matchwrapper">
                                    <p className="teams">Italy</p>
                                    <p className="versus">VS</p>
                                    <p className="teams">France</p>
                                </div>
                                {/* <div className="watch-link" data-matchid={item.MatchID}>
                                    <a href={`${process.env.REACT_APP_LIVE_ENDPOINT}/${item.MatchID}`}>
                                        Watch Live
                                    </a>
                                </div> */}
                            </div>
                            <div className="matchImage">
                                <img src={player1} alt="football player" />
                            </div>
                        </div>
                    
                

                ) }
              
            </Slider>
            <div className="arrowNav">
                <button ref={prevButtonRef} className="slick-btn prev-btn slick-prev" onClick={() => sliderRef.current.slickPrev()}><Icon>chevron_left</Icon></button>
                <button ref={nextButtonRef} className="slick-btn slick-next next-btn" onClick={() => sliderRef.current.slickNext()}><Icon>chevron_right</Icon></button>
            </div>
        </div>
    );
};

export default Banner;
