import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate  } from 'react-router-dom';
import { selectFilteredData, setActiveCategory ,setCurrentPage } from '../storage/store';
import { Typography } from '@mui/material';
const Matches = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const filteredData = useSelector(selectFilteredData);
    const { loading, error } = useSelector(state => state.data);
    const { category = 'all', page } = useParams();  // Default category to 'all'
    
    const currentPage = parseInt(page, 10) || 1;
    const itemsPerPage = 9;
    const currentItems = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    useEffect(() => {
        dispatch(setActiveCategory((category || 'all').toUpperCase()));
        dispatch(setCurrentPage(currentPage));
        
        // Fix invalid navigation for missing category or page
        if (!category || !page || currentPage > totalPages) {
            dispatch(setActiveCategory((category || 'all').toUpperCase()));
            dispatch(setCurrentPage(currentPage));
            
        }
        else{
            navigate(`/matches/${category || 'all'}/${currentPage}`);
        }
    }, [category, page, currentPage, totalPages, dispatch, navigate]);

    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    if (error) {
        return <div className='match-container'><div>Error: {error}</div></div>;
    }

    if (!filteredData || filteredData.length === 0) {
        return <div>No data available</div>;
    }

    if (currentPage > totalPages && totalPages > 0) {
        return (
            <div className="match-container">
                <div>Error: The page number {currentPage} is out of range. Only {totalPages} pages are available.</div>
                <Link to={`/${category || 'all'}/1`}>Go to the first page</Link>
            </div>
        );
    }

    return (
        <div className='match-container'>
            <div className="match-header">
                <Typography variant='h2' color="white" sx={{ fontSize: '2rem', fontWeight:700 }}> Matches</Typography>
                <Typography variant='p' color="white" sx={{ fontSize: '1rem', fontWeight:500 }}> Showing {currentItems.length} of {filteredData.length} result</Typography>
            </div>
            <div className='matchList'>
                {currentItems.map(item => {
                    const isBroadcast = item.IsLive === item.NowPlaying.toString() ? 0 : 1;
                    const sched = new Date(item.TimeStart);
                    const month = sched.getMonth();
                    return (
                        <div className={`matchBox ${item.Type}`} data-matchid={item.MatchID} key={item.MatchID}>
                            <div className="matchinner">
                                <div className="schedule">
                                    <p><img src="/img/date_icon.svg" alt="date icon" /> {months[month] + " " + sched.getDate() }</p>
                                    <p><img src="/img/time_icon.svg" alt="time icon" /> {sched.toLocaleTimeString([], { timeStyle: 'short', timeZone: 'Asia/Dhaka' })}</p>
                                </div>
                                <div className="League">{item.League}</div>
                                <div className="matchwrapper">
                                    <p className="teams">{item.Home}</p>
                                    <p className="versus">VS</p>
                                    <p className="teams">{item.Away}</p>
                                </div>
                            </div>
                            <div className="matchlink">
                                <span className={`isLive ${isBroadcast ? 'hide' : 'show'}`}>Live</span>
                                <div className="btn-link" data-matchid={item.MatchID}>
                                    <a href={`${process.env.REACT_APP_LIVE_ENDPOINT}/${item.MatchID}`}><img src="/img/play_icon.svg" alt="play icon" /></a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="pagination">
                <Link   to={`/matches/${category}/${currentPage - 1}`} className= { currentPage === 1 ? 'disabled' : ''} disable={currentPage === totalPages ? 'disabled' : ''}>Previous</Link>
                <span id="pageInfo"> Page {currentPage} of {totalPages} </span>
                <Link  to={`/matches/${category}/${currentPage + 1}`} className={currentPage === totalPages ? 'disabled' : ''} disable={currentPage === totalPages ? 'disabled' : ''}>Next</Link>
            </div>
        </div>
    );
};

export default Matches;
